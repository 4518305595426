import React from 'react';
import { Container } from 'react-bootstrap';
import Button from '@/components/Button';
import { TypeWrite } from '@/components';
import { buttonEvents, homeButtonNames } from '@/constants/analytics';
import { trackButtonClick } from '@/services/analytics';

import softwareOdontologico from '@/assets/images/software-odontologico.svg';
import Verified from '@/assets/svg/verified.svg';
import * as styles from './hero.module.scss';
import Image from '@/components/Image';
import { navigate } from 'gatsby';
import { routes } from '@/constants/routes';

const Hero = () => {
  const strings = ['dentistas.', 'secretárias.', 'clínicas.', 'consultórios.'];

  const linkEducate =
    'https://ajuda.simplesdental.com/pt-BR/articles/6387374-como-realizar-o-reembolso-da-mensalidade';

  const handleTestarGratis = () => {
    navigate(routes.cadastro);
    trackButtonClick({
      event: buttonEvents.home,
      buttonName: homeButtonNames.testarGratisHero,
    });
  };

  const handleFalarEspecialista = () => {
    navigate(routes.cadastroEspecialista);
    trackButtonClick({
      event: buttonEvents.home,
      buttonName: homeButtonNames.falarEspecialistaHero,
    });
  };

  return (
    <>
      <Container>
        <div className={styles.container}>
          <div className={styles.heroText}>
            <h1 className={styles.title}>
              O melhor software odontológico para <TypeWrite strings={strings} />
            </h1>
            <h2 className={styles.subtitle}>
              O Simples Dental tem todas as ferramentas que você precisa para sua clínica crescer.
            </h2>
            <div className={styles.heroActions}>
              <div className={styles.heroButtons}>
                <Button text="Testar grátis 7 dias" variant="orange" onClick={handleTestarGratis} />
                <Button
                  text="Falar com especialista"
                  variant="green"
                  onClick={handleFalarEspecialista}
                />
              </div>
            </div>
            <hr />

            <div className={styles.desc}>
              <Verified className={styles.descIcon} />
              <div className={styles.descText}>
                <h3>
                  Satisfação garantida ou seu dinheiro de volta,{' '}
                  <a href={linkEducate} target="_blank" rel="noreferrer">
                    saiba mais
                  </a>
                </h3>
                <p>
                  Mais de 79 mil profissionais de odontologia usam o Simples Dental todos os dias!
                </p>
              </div>
            </div>
          </div>
          <Image
            type="svg"
            src={softwareOdontologico}
            alt="Software odontológico"
            title="Imagem do Software odontológico da Simples Dental"
            className={styles.imgDesktop}
            width={866}
            height={535}
          />
        </div>
      </Container>
      <div className={styles.after}></div>
    </>
  );
};

export default Hero;
